import { Component, OnInit,	ViewChild, OnDestroy } from "@angular/core";
import { Member } from "../model/member";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ModalService } from "../_ui/modal.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../_ui/components/dialog/dialog.component";
import { ConvertPointsFormComponent } from '../account/convert-points-form/convert-points-form.component';
import { UserService } from '../service/user.service';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { WebsocketService } from '../service/websocket.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

export interface RefData {
  date_created: string;
  invited_full_name: string;
  activation_status: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})

export class DashboardComponent implements OnInit, OnDestroy {
  location_url;

  user: any;
  public status: string;
  pointsToConvert: Number;
  referralInvalid: boolean;
  referralsLeft: number = 3;
  userCurrentPoints: number;
  userWallet: any;
  uid: any;
  referralCode;
  country_code: any;

  referrals: any;
  public REFERRAL_DATA: RefData [] = [];
  
  displayedColumns: string[] = ['invited_full_name', 'activation_status', 'date_created'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  navigationSubscription;
  noData;

  public language: any;


  constructor(private route: ActivatedRoute, 
    private userService: UserService, private dialog: MatDialog, 
    private router: Router,
    private webSocketService: WebsocketService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private toastr: ToastrService,
    ) {

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          this.location_url = environment.domain;
          this.getUser();
          this.initReferralTable();
        }
      });


    }

  ngOnDestroy(){
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }


  ngOnInit() {

    if(localStorage.getItem('lang')){
      this.language = localStorage.getItem('lang');
    }else{
      this.language = 'EN';
    }

    $(document).ready(function(){

      $('.next').click(function(){
      
        var nextId = $(this).parents('.tab-pane').next().attr("id");
        var nextBtn:any = $('[href="#'+nextId+'"]');
        nextBtn.tab('show');
        return false;
        
      })
  
      $('.prev').click(function(){        
          var prevId = $(this).parents('.tab-pane').prev().attr("id");
          var prevBtn:any = $('[href="#'+prevId+'"]');
          prevBtn.tab('show');
          return false;
          
      })
  
      
  
      $('#how-modal').on('hide.bs.modal', function (e) {
        var wizModal:any = $('#myWizard a:first');
          wizModal.tab('show')
        })

        function testAnim(x) {
          $('.modal .modal-dialog').attr('class', 'modal-dialog  ' + x + '  animated faster');
        };
      
        $('#how-modal').on('show.bs.modal', function (e) {
          var anim = "zoomIn";
              testAnim(anim);
        })
        $('#how-modal').on('hide.bs.modal', function (e) {
          var anim = "zoomOut"
              testAnim(anim);
        })
      
        
  
  });

    // Refetch user data on update/save/post/
    this.userService.refreshData$.subscribe(() => {
      this.getUser();
      // this.showConvertDialog();
    });

    this.loadUser();
    this.initReferralTable();
    // let modalData = {title: "Mechanics"}; 
    // this.modalService.showDialog(this.user);
    

    this.uid = this.user.unique_id;
    this.country_code = environment.country_code;

    // refresh points via websocket
    this.webSocketService.connectPointsWebSocket(this.uid).subscribe(
      message => {
          // refresh user data
          this.userCurrentPoints = message["total_points"];
          this.userWallet = message["wallet"];
          this.userService.refreshData$.next();
      },
      error => {
        console.error(error); // handle errors
      }
    );

    // refresh table via websocket
    this.webSocketService.connectReferralWebSocket(this.uid).subscribe(
      (ref:any) => {

        // check activated referrals
        let member_details = ref;
        let counter = 0;
        member_details.forEach(element => {
          if(element.activation_status=="Activated"){
            counter++;
            this.referralsLeft = 3 - counter;
          }
        });
           // valid referrals is 3
          if (this.referralsLeft > 0){
            this.referralInvalid = true;
          }else{
            this.referralInvalid = false;
          }
          this.userService.refreshData$.next();

          this.REFERRAL_DATA = ref;
          this.dataSource = new MatTableDataSource(this.REFERRAL_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // indicator for showing No records message
          this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));

        },
      error => {
        console.error(error); // handle errors
      }
    );

  }

  private loadUser() {
    this.user = this.route.snapshot.data.user;
    this.referralCode = this.user.referral_code;
    this.userCurrentPoints = this.user.total_points;
    this.userWallet = this.user.wallet;
  }

  private getUser() {
    this.userService.getUser().subscribe((user: any) => {
      this.user = user;
      this.referralCode = this.user.referral_code;
      this.userCurrentPoints = this.user.total_points;
      this.userWallet = this.user.wallet;
      if(this.user.is_referral_max==false && this.user.referral_count >= 3){
        this.openDialog();
        this.user.is_referral_max=true;
        this.userService.updateUser(this.user).subscribe(data => {});
      }
      if(this.user.is_activated==false){
        this.modalService.showDialog(this.user);
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {});
  }



  private initReferralTable(){
    // initialize table from api
    this.userService.getReferrals().subscribe((ref:any)=>{
      // check activated referrals
      // console.log(ref.data);
      let member_details = ref.data;
      let counter = 0;
      member_details.forEach(element => {
        if(element.activation_status=="Activated"){
          counter++;
          this.referralsLeft = 3 - counter;
        }
      });
      
      // valid referrals is 3
      if (this.referralsLeft > 0){
        this.referralInvalid = true;
      }else{
        this.referralInvalid = false;
      }

      // refresh user data
      this.REFERRAL_DATA = ref.data;
      this.dataSource = new MatTableDataSource(this.REFERRAL_DATA);
      this.dataSource.paginator = this.paginator;

      // this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      //   return this.getPropertyByPath(data, sortHeaderId);
      // };

      this.dataSource.sort = this.sort;
      // indicator for showing No records message
      this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
    });
  }

  // conversion modal
  public showConvertDialog(): Observable<any> {

      const dialogRef = this.dialog.open(ConvertPointsFormComponent, {
        width: '250px',
        data: {
          user: this.user,
          referralInvalid: this.referralInvalid,
          pointsToConvert: this.pointsToConvert,
        }
      });
  
      return dialogRef.afterOpened();
  }

  // copy shortcut
  public notify(event: string): void {

    this.toastr.success(
      "Referral Code Copied", "",
      {
        positionClass: "toast-top-right",
        // closeButton: true,
        // timeOut: 3000,
        // easeTime: 3000
      }
    );
  }

  public copyReferralCode(): string {
    return environment.domain + "/referral-code/" + this.user.referral_code;
  }

  // custom filter for nested objects
  applyFilter(filterValue: string) {
    this.dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm, key) => {
        if(key === 'date_created'){     
          // convert datetime with timezone
          var date = new Date(data.date_created);
          return currentTerm + this.datePipe.transform(date, "full");
        }else{
          return currentTerm + data[key];
        }
      };

      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
    

}
