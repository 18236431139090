import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Member } from "../model/member";
import { AuthenticationService } from "../service/authentication.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UserService } from "../service/user.service";
import { DialogComponent } from "../_ui/components/dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { environment } from "src/environments/environment";
// import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, interval } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { WebsocketService } from '../service/websocket.service';

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() country_code: any;
  apiEndpoint = environment.apiEndpoint;
  navigationSubscription;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private webSocketService: WebsocketService,
  ) {

    // get client location and ip
    // this.userService.getClientLoc().subscribe((data:any)=>{
    //   this.clientLoc = data.country_code;
    //   console.log(this.clientLoc);
    // });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // this.initConfig();
      }
    });

  }

  ngOnDestroy(){
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {  
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // initialize payment gateway config
    // this.initConfig();
  }


  
  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: "logout",
        message: "Are you sure want to logout?",
        buttonText: {
          ok: "Yes",
          cancel: "No"
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Close Websockets
        if(this.webSocketService !== undefined){
          this.webSocketService.closePointsWebSocket();
          this.webSocketService.closeReferralWebSocket();
        }
        this.logout();
      }
    });
  }

  // private initConfig(): void {
  //   this.payPalConfig = {
  //     currency: "PHP",
  //     clientId:
  //       "AeKUyzT-nZmObmpVqNmRLFiZT4ZDhvFq0z1bABH_MoYh8SN9N-SWxMan7hK_UGc-tcr8dccT9aXqarEy",
  //     createOrderOnClient: data =>
  //       <ICreateOrderRequest>{
  //         intent: "CAPTURE",
  //         purchase_units: [
  //           {
  //             amount: {
  //               currency_code: "PHP",
  //               value: "100",
  //               breakdown: {
  //                 item_total: {
  //                   currency_code: "PHP",
  //                   value: "100"
  //                 }
  //               }
  //             },
  //             items: [
  //               {
  //                 name: "Account Activation",
  //                 quantity: "1",
  //                 category: "DIGITAL_GOODS",
  //                 unit_amount: {
  //                   currency_code: "PHP",
  //                   value: "100"
  //                 }
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //     advanced: {
  //       commit: "true"
  //     },
  //     style: {
  //       label: "pay",
  //       layout: "horizontal",
  //       fundingicons: false,
  //       tagline: false
  //     },
  //     onApprove: (data, actions) => {
  //       this.spinner.show();
  //       console.log(
  //         "onApprove - transaction was approved, but not authorized",
  //         data,
  //         actions
  //       );
  //       actions.order.get().then(details => {
  //         console.log(
  //           "onApprove - you can get full order details inside onApprove: ",
  //           details
  //         );
  //         this.activate();
  //       });
  //     },
  //     onClientAuthorization: data => {
  //       // console.log(
  //       //   "onClientAuthorization - you should probably inform your server about completed transaction at this point",
  //       //   data
  //       // );
  //       this.activate();
  //     },
  //     onCancel: (data, actions) => {
  //       console.log("OnCancel", data, actions);
  //       this.spinner.hide();
  //     },
  //     onError: err => {
  //       console.log("OnError", err);
  //       this.spinner.hide();
  //     },
  //     onClick: (data, actions) => {
  //       console.log("onClick", data, actions);
  //     }
  //   };
  // }

  activate() {
    this.userService.activate().subscribe(
      response => {
        // localStorage.setItem("Status", "Account Activated");
        // location.reload();
        // this.router.navigate(["/"]);
        // reload data
        this.userService.refreshData$.next();
        this.router.navigate([this.router.url]);
        this.spinner.hide();
        this.toastr.success(
          "You have successfully activated your account.",
          "Congratulations",
          {
            positionClass: "toast-top-right",
            closeButton: true,
            timeOut: 3000
          }
        );
      },
      error => {
        this.router.navigate(["/"]);
        this.spinner.hide();
      }
    );
  }
}
