import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../service/authentication.service';
import { ErrorStateMatcher, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Member } from 'src/app/model/member';
import { UserService } from 'src/app/service/user.service';
import { ModalService } from 'src/app/_ui/modal.service';

import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { CookieService } from "ngx-cookie-service";
import { CompleteProfileModalComponent } from 'src/app/_ui/components/complete-profile-modal/complete-profile-modal.component';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || isSubmitted));
    }
}

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    returnUrl: string;
    user: any;
    public member: Member;
    loading = false;
    loginError;
    country_code;

    constructor(
        private dialog: MatDialog,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private userService: UserService,
        private spinner: NgxSpinnerService,
        private cookie: CookieService,
    ) {
        // remove referral code cookies
        localStorage.removeItem('inviterDetails');
        localStorage.removeItem('referralCode');

        // remove resend email cookie
        localStorage.removeItem('emailToken');
        
        this.country_code = environment.country_code;

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }

        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        
    }

      
    // Custom mat-error validation
    matcher = new MyErrorStateMatcher();

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if(localStorage.getItem('verification') == 'success'){
            this.toastr.success("You have successfully verified your account. You can now login.", 'Congratulations',
            {
              positionClass: 'toast-top-center',
              closeButton: true,
              timeOut: 7000
            });
            localStorage.removeItem('verification');
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.loading = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        // this.spinnerOverlay.show();
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.toastr.clear();
                    this.loading = false;
                    this.spinner.show();
 
                    setTimeout(() => {
                      this.router.navigate([this.returnUrl], { queryParams: { login: true } });
                      /** spinner ends after 3 seconds */
                      this.getUser();
                    //   location.reload(true);
                      // console.log(data);
                    }, 2500);

                },
                error => {
                    this.loading = false;
                    this.loginError = error;
                    this.toastr.error("Email/Password doesn't exist", 'Invalid Credentials',
                    {
                        positionClass: 'toast-top-center',
                        closeButton: true
                    });
                    // this.spinnerOverlay.hide();
                });
    }

    private getUser() {
        this.userService.getUser().subscribe(
            (user: Member) => {
                this.user = user;
                // this.toastr.success("Welcome " + this.user.get_full_name + "!", '',
                //     {
                //         positionClass: 'toast-top-center',
                //         closeButton: true
                //     });
                // this.modalService.showDialog();
                // this.spinnerOverlay.hide();
                // if(this.user.is_first_login==true){
                //     this.user.is_first_login=false;
                //     this.modalService.showDialog(this.user);
                //     this.userService.updateUser(this.user).subscribe(data => {});
                // }       
                 
                // SHOW COMPLETE PROFILE if not completed yet

                if(this.user.is_activated==true){
                    if(this.user.buyer){
                        if(this.user.buyer.birth_date==null || this.user.line_1_address==null || this.user.buyer.gender==null || this.user.contact_number==null || this.user.city==null || this.user.zip_code==null || this.user.buyer.birth_date=='' || this.user.line_1_address=='' || this.user.buyer.gender=='' || this.user.contact_number=='' || this.user.city=='' || this.user.zip_code==''){
          
                            const dialogRef = this.dialog.open(CompleteProfileModalComponent, {
                              // width: this.width,
                              data: {user: user},
                              panelClass: 'custom-dialog-card'
                            });
                  
                          }
                    }else{
                        if(this.user.line_1_address==null || this.user.contact_number==null || this.user.city==null || this.user.zip_code==null || this.user.buyer.birth_date=='' || this.user.line_1_address=='' || this.user.buyer.gender=='' || this.user.contact_number=='' || this.user.city=='' || this.user.zip_code==''){
          
                            const dialogRef = this.dialog.open(CompleteProfileModalComponent, {
                              // width: this.width,
                              data: {user: user},
                              panelClass: 'custom-dialog-card'
                            });
                  
                          }
                    }
                }
                                
            }
        )
    }

}
