import { Component, OnInit, ComponentFactoryResolver, ViewChild } from "@angular/core";

import { UserService } from 'src/app/service/user.service';

import { CookieService } from "ngx-cookie-service";

import { AccountRegisterItem } from "src/app/account/account-register/account-register";
import { AccountRegisterDirective } from "src/app/account/account-register/account-register.directive";
import { BuyerRegisterComponent } from "src/app/account/account-register/buyer-register/buyer-register.component";
import { SellerRegisterComponent } from "src/app/account/account-register/seller-register/seller-register.component";
import { AuthenticationService } from 'src/app/service/authentication.service';


@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.scss']
})

export class AccountRegisterComponent implements OnInit {
  public AccountRegisterItem: AccountRegisterItem;
  @ViewChild(AccountRegisterDirective, {static: false}) registerHost: AccountRegisterDirective;

  public inviterDetails: any;
  userTypeChoices: string[] = ['Club Member', 'Participating Store', 'Service Provider'];
  public user_type;
  currentUser;
  
  constructor(private userService: UserService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cookie: CookieService,
    private authenticationService: AuthenticationService
    ) {
    // inviter details
    // this.inviterDetails = this.cookie.get('inviterDetails');
    this.inviterDetails = localStorage.getItem('inviterDetails');
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {

  }

  // getRegistrationForm(userType){
  //   if(userType=="Club Member"){
  //     this.getBuyerForm();
  //   }else{
  //     this.getSellerForm();
  //   }
  // }

  getBuyerForm(){
    this.AccountRegisterItem = new AccountRegisterItem(BuyerRegisterComponent, {});
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.AccountRegisterItem.component);
    const viewContainerRef = this.registerHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

  getSellerForm(type){
    this.AccountRegisterItem = new AccountRegisterItem(SellerRegisterComponent, {seller_type: type});
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.AccountRegisterItem.component);
    const viewContainerRef = this.registerHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    // add data on the component
    componentRef.instance.data = this.AccountRegisterItem.data;
  }

  logout() {
    this.authenticationService.logout();
    location.reload(true);
  }

}
