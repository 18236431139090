import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { Member } from 'src/app/model/member';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'src/app/service/location.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import {debounceTime, delay, tap, filter, map, takeUntil} from 'rxjs/operators';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { State, STATES } from 'src/app/model/state';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { CookieService } from "ngx-cookie-service";

import { MatDialog } from "@angular/material";
import { DialogComponent } from "src/app/_ui/components/dialog/dialog.component";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-buyer-register',
  templateUrl: './buyer-register.component.html',
  styleUrls: ['./buyer-register.component.scss']
})

export class BuyerRegisterComponent implements OnInit, OnDestroy {
  public member: any;
  public regForm: FormGroup;
  public inviterDetails: any;
  public country_code;

  unhide: boolean;
  confirm_password_unhide: boolean;
  loadingBuyer = false;
  provinces: Array<any>;
  cities: Array<any>;


  /** list of states */
  protected states: State[] = STATES;

  /** control for filter for server side. */
  public stateServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of states filtered after simulating server side search */
  public  filteredServerSideStates: ReplaySubject<State[]> = new ReplaySubject<State[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  us_cities: Array<any>;

  submitted = false;
  disabledAgreement: boolean = true;
  // loadingBuyer = false;

  get formArray(): AbstractControl | null {
    return this.regForm.get('formArray');
  }

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private locations: LocationService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private cookie: CookieService,
    private dialog: MatDialog,
    ) {

    // inviter details
    this.inviterDetails = localStorage.getItem('inviterDetails');

    // get country code of client
    this.country_code = environment.country_code;

    // get states for US
    // this.locations.getUSstates().subscribe((data:any) => {
    //   var results = [];
    //   for (const state in data) {
    //     if (data.hasOwnProperty(state)) {
    //       const element = data[state];
    //       results.push(state);
    //     }
    //   }
    //   this.states = results.sort();
    // });

    this.searchStates();


    // Form validations
    this.regForm = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          user_type: ['1'],
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          contact_number: ['', Validators.required],
          line_1_address: ['', Validators.required],
          line_2_address: ['', Validators.required],
          city: ['', Validators.required],
          state: ['', Validators.required],
          zip_code: ['', Validators.required],
        }),
        this.formBuilder.group({
          email: ['', Validators.compose([Validators.required, Validators.email])],
          password: ['', Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            // CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            // 4. check whether the entered password has a lower-case letter
            // CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            // CustomValidators.patternValidator(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\sa-zA-Z0-9]).{8,}/, { hasSpecialCharacters: true }),
            Validators.minLength(8)
          ])],
          confirm_password:['', RxwebValidators.compare({ fieldName:'password' })],
        }),
        // {
        //   // check whether our password and confirm password match
        //   validator: this.passwordMatchValidator
        // }
      ])
    });

  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        type: "register",
        message: "Please confirm if the information you entered are correct.",
        buttonText: {
          ok: "Confirm",
          cancel: "Cancel"
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.createMember();
      }
    });
  }


  // Custom mat-error validation
  matcher = new MyErrorStateMatcher();
  ngOnInit() {

    // get referral code from url
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     this.referral_code = params['ref'];
  // });
  }

  checkAgreement(event) {
    this.disabledAgreement = !event.checked;
  }

  /** listen for search value in states */
  searchStates(){
    // listen for search field value changes
    this.stateServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.states) {
            return [];
          }
          // simulate server fetching and filtering data
          return this.states.filter(state => state.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(500)
      )
      .subscribe(filteredstates => {
        this.searching = false;
        this.filteredServerSideStates.next(filteredstates);
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
  }
  

  // US cities
  // getUScities(e) {
  //   this.locations.getUSstates().subscribe(data => {
  //     var results = [];
  //     for (var i = 0; i < data[e].length; i++){
  //       // console.log(data[e][i]);
  //       results.push(data[e][i]);
  //     }
  //     this.us_cities = results.sort();
  //   });
  // }


  createMember() {

    this.submitted = true;
    
   // stop here if form is invalid
    if (this.regForm.invalid) {
      return;
    }
    delete this.regForm.value.confirm_password;
    this.member = Object.assign(this.formArray.value[0], this.formArray.value[1]);
    // console.log(this.member);




    this.loadingBuyer = true;
    this.userService.register(this.member, localStorage.getItem('referralCode'))
      .subscribe(data => {
        // redirect to login after registration
        this.loadingBuyer = false;
        // this.toastr.success("Please check your email to verify your account.", 'Registered Successfully',
        //   {
        //     positionClass: 'toast-top-center',
        //     closeButton: true,
        //     timeOut: 10000
        //   });
        localStorage.setItem('emailToken', data.token);
        // this.cookie.set('emailToken', data.token);
        localStorage.removeItem('inviterDetails');
        // this.cookie.delete('inviterDetails');
        localStorage.removeItem('referralCode');
        // this.cookie.delete('referralCode');
          this.router.navigate(['/register/success']);
      }, error => {
        this.loadingBuyer = false
        const validationErrors = error.ValueError;
        // console.log(error)
        // if (validationErrors){
        // console.log(this.formArray['controls'][1].get('email'))
        this.formArray['controls'][1].get('email').setErrors({ notUnique: true });

      });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
