import { Injectable } from '@angular/core';
import { UserService } from '../service/user.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Member } from '../model/member';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<Member> {

    user:any; 

    constructor(private userService: UserService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        any | Observable<any> | Promise<any> {
            const user = this.userService.getUser();
            return user;
    }

}

