import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  uidb64;
  token;

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    this.spinner.show();
    this.uidb64 = this.route.snapshot.queryParamMap.get('uidb64');
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.verify();
  }

  verify() {

    if (this.uidb64 && this.token) {
      this.userService.verify(this.uidb64, this.token).subscribe(
        (response) => {
          localStorage.setItem('verification','success');
          this.spinner.hide();
          this.router.navigate(['/login']);
        }, (error) =>{
          console.log(error)
          // this.router.navigate(['/']);
          this.spinner.hide();
        }
      )
    } else {
      // this.router.navigate(['/']);
    }

  }

}
